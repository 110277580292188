import { initialiseSwiper } from './swiper/swiper.js';
import { initShowroomFinders } from './showrooms/showroomFinder.js';
import { RecentSwatches } from './swatch/RecentSwatches.js';
import { handleFragmentInit } from './pdp/fragments';
import { replaceElementHTML } from './pdp/replace';

initShowroomFinders();

const initRecentSwatches = () => {
  const recentSwatchContainer = document.querySelector(
    '[data-recent-swatches]',
  );

  if (!recentSwatchContainer) {
    return;
  }

  const recentSwatches = new RecentSwatches();
  const swatchGridModifierClasses = ['swiper-wrapper', 'swatch-grid--block'];

  recentSwatches.renderRecentSwatches(swatchGridModifierClasses);
};

initRecentSwatches();

handleFragmentInit(({ replace }) => {
  if (replace?.crossSell) {
    replaceElementHTML('.block--cross-sells', replace.crossSell);
  }

  const carousels = document.querySelectorAll('.block .swiper');
  // This is reasonable as we exit out if a swpier alreaddy exists. Though perhaps not ideal.
  for (const carousel of carousels ?? []) {
    initialiseSwiper(carousel);
  }
});

const initReadMore = () => {
  const readMoreContainers = document.querySelectorAll('.block__content .read-more');

  if (!readMoreContainers) {
    return;
  }

  readMoreContainers.forEach((container) => {
    const button = container.querySelector('.js-read_more');

    if (!button) {
      return;
    }

    button.addEventListener('click', () => {
      container.classList.toggle('read-more--active');
      
      if (container.classList.contains('read-more--active')) {
        button.textContent = 'Read less';
      } else {
        button.textContent = '... Read more';
      }
    });
  });
}

initReadMore();
